.pagination-container {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 25px auto;

  .pagination-item {
    height: 36px;
    text-align: center;
    margin: auto 4px;
    color: #414855;
    border-radius: 50%;
    font-family: 'Co-Headline';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    width: 36px;
    transition: background-color 0.3s ease;
    user-select: none;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: #47ad42;
      color: white;
    }

    &.arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      &.left {
        margin-left: -9px;

        html[dir='rtl'] & {
          transform: rotate(180deg);
          margin-left: 0;
          margin-right: -9px;
        }
      }

      &.right {
        html[dir='rtl'] & {
          transform: rotate(180deg);
        }
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      user-select: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
