video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
  top: calc(100% - 600px);

  body.our-brands &,
  body.our-values & {
    top: auto;
    bottom: 20px;
  }

  @media (min-width: 1100px) {
    top: auto;
    bottom: 20px;

    body.our-brands &,
    body.our-values & {
      top: auto;
      bottom: 20px;
    }

    html[dir='rtl'] & {
      top: 375px;
    }
  }

  div,
  h2 {
    z-index: $z-one * 3;
  }

  &__descHero {
    max-width: 325px;
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 22px;
    font-family: 'Calibri';
    max-width: 265px;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
      font-size: 14px;
      max-width: 265px;
    }

    @media (min-width: $bp-large-min) {
      max-width: 585px;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.333;

      html[dir='rtl'] & {
        font-size: 24px;
        max-width: 460px;
      }
    }
  }

  &__img {
    height: 135px;
    width: 124px;
    opacity: 0.7;
    position: relative;
    left: -25px;
    top: 62px;
    z-index: $z-one * 3;

    body.our-brands &,
    body.our-values & {
      height: 87px;
      width: 69px;
      left: -5px;
      top: 30px;
    }

    @media (min-width: $bp-large-min) {
      height: 240px;
      width: 207px;
      position: relative;
      top: 102px;

      body.our-brands &,
      body.our-values & {
        height: 242px;
        width: 192px;
        top: 130px;
      }

      html[dir='rtl'] & {
        width: 207px;
        height: 240px;
        position: absolute;
        top: -98px;
        right: -25px;

        body.our-brands &,
        body.our-values & {
          left: auto;
          right: -5px;
        }
      }
    }

    @media (min-width: $bp-navigation-min) {
      left: -80px;
      html[dir='rtl'] & {
        right: -64px;
      }

      body.our-brands &,
      body.our-values & {
        left: -45px;
        html[dir='rtl'] & {
          left: auto;
          right: -45px;
        }
      }
    }
  }

  &__welcome {
    font-size: 22px;
    letter-spacing: 0.08em;
    line-height: 1.2;
    font-family: 'Co-Headline';
    font-weight: 300;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
      font-size: 20px;
    }

    @media (min-width: $bp-large-min) {
      font-weight: 400;
      font-size: 32px;
      line-height: 5px;
      font-family: 'Co-Headline';
    }

    html[dir='rtl'] & {
      font-family: 'Tajawal';
      font-size: 32px;
      letter-spacing: 0.08em;
      line-height: 1.18;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 1.111;
    font-family: 'Co-Headline';
    font-weight: 700;
    margin-bottom: 15px;

    body.our-brands &,
    body.our-values & {
      font-weight: 400;
    }

    html[dir='rtl'] & {
      font-family: 'Tajawal';
      font-size: 37px;
    }

    @media (min-width: $bp-large-min) {
      font-weight: 700;
      font-size: 64px;
      line-height: 1.1875;
      font-family: 'Co-Headline';
      font-weight: 700;
      margin-bottom: 10px;

      html[dir='rtl'] & {
        line-height: 1.18;
        font-family: 'Tajawal';
        font-size: 64px;
      }

      body.our-brands &,
      body.our-values & {
        font-size: 88px;
        font-weight: 400;
      }

      body.our-values & {
        max-width: 640px;
      }
    }
  }
}

.icon {
  position: relative;

  &__down {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 65px;
    cursor: pointer;
    z-index: $z-one * 3;
  }
}

.header-main {
  background: rgba(17, 87, 64, 0.5);
  backdrop-filter: blur(10px);
  position: relative;
  height: 100vh;
  width: 100%;
}

.heroimage {
  &__wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
  }

  &__image {
    aspect-ratio: 2 / 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-one;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      content: ' ';
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: $z-one * 2;
      display: block;
    }
  }
}
