@import './../abstracts/mixins';

.white-box {
  padding: 66px 50px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 20px 20px -15px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: -20vh;
  position: relative;
  z-index: $z-one;
  @media (max-width: $bp-medium-min) {
    width: 100%;
    padding: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.contact {
  &__container {
    display: flex;
    flex-direction: row;
    gap: 45px;
    @media (max-width: $bp-medium-min) {
      gap: 10px;
    }
    &--left {
      flex-direction: row-reverse;
    }

    @media (max-width: $bp-medium-min) {
      flex-direction: column-reverse;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex: 1;

    h2 {
      background: linear-gradient(270.17deg, #ed6940 50%, #bc204b 98.6%);
      @include text-clip;
      font-family: 'Co-Headline';
      color: #bc204b;
      margin-bottom: 7px;
      font-size: 36px;
      @media screen and (max-width: $bp-medium-900) {
        font-size: 24px;
      }
    }
    div {
      font-family: 'Calibri';
      font-size: 18px;
      color: #414855;
      margin-bottom: 11px;
    }
    a {
      max-width: 183px;
    }
  }

  &__Img {
    flex: 1;
  }

  &__border {
    border: 1px solid rgb(130 145 165 / 15%);
    margin-top: 36px;
    margin-bottom: 40px;
  }

  &__border:last-child {
    border: unset;
  }
}

.investment-textArea {
  margin-top: 0;
  height: 50px;
  border: unset;
  border-bottom: 1px solid #414855;
  margin-bottom: 25px;
  width: 100%;
}
.interestedIn-container {
  padding: 0;
  border: unset;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
}

.interested-lable {
  font-size: 12px;
  font-family: 'Calibri';
  font-weight: 700;
  color: #414855;
  margin-bottom: 16px;
}

.sector-group-container {
  display: flex;
  border: unset;
  padding: 0;
  margin: 0;
  gap: 110px;
  @media screen and (max-width: $bp-medium-900) {
    gap: 45px;
  }

  .sector-group {
    display: flex;
    flex-direction: column;
    border: unset;
    padding: 0;
    margin: 0;

    label {
      display: flex;
      align-items: center;
      gap: 11px;
      font-size: 18px;
      font-family: 'Calibri';
      color: #414855;
      font-weight: 400;
      @media screen and (max-width: $bp-medium-900) {
        font-size: 16px;
      }
    }
    input {
      width: 20px;
      height: 20px;
      margin: 0;
      cursor: pointer;
    }
  }
}

.file-share-label {
  color: #414855 !important;
  font-family: 'Co-Headline' !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  margin-bottom: 12px !important;
}

.file-share-input {
  border: 1px dashed rgba(65, 72, 85, 0.4588235294) !important;
  max-width: 385px !important;
  color: #414855 !important;
  box-shadow: 0px 1px 4px 2px rgb(51 51 51 / 14%);
  border-radius: 2px;
  cursor: pointer;
  padding: 9px 10px;
  background: rgb(246 250 241 / 12%);
}
