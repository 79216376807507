.article {
  &__box {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    // background: white;
    background: url(../../img/article-bg.png) center bottom no-repeat #fff;
    background-size: contain;
    z-index: $z-one;
    height: 100%;
    width: 100%;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 121px 10px;
    width: 100%;
    top: -35vh;
    z-index: 2;

    @media (min-width: $bp-large-min) {
      padding: 55px 305px 299px;
      top: -20vh;
    }
  }

  &__date {
    color: #89ccca;
    font-family: 'Co-Headline';
    font-weight: 700;
    margin-bottom: 4px;
    font-size: 12px;
    @media (min-width: $bp-large-min) {
      font-size: 16px;
      width: 697px;
    }
  }

  &__title {
    font-family: 'Co-Headline';
    color: #bc204b;
    margin-bottom: 19px;
    font-size: 24px;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }

    @media (min-width: $bp-large-min) {
      font-size: 48px;
      width: 697px;
    }
  }

  &__desc {
    font-size: 16px;
    line-height: 1.3;
    font-family: 'Calibri';

    @media (min-width: $bp-large-min) {
      font-size: 18px;
    }

    p {
      margin-bottom: 15px;
      font-family: 'Calibri';

      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }
    }

    div {
      font-family: 'Co-Headline';
      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }
    }
    p:nth-of-type(1) {
      font-weight: 700;
    }
  }

  &__image {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 15px;
    font-family: 'Calibri';

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }
  }

  &__second-title {
    color: #78d64b;
    font-size: 24px;
    font-family: 'Co-Headline';
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 13px;
    @media (min-width: $bp-large-min) {
      font-size: 40px;
      width: 824px;
    }
  }

  &__prince-mohammed {
    color: #115740;
    font-family: 'Co-Headline';
    font-weight: 700;
    margin-bottom: 29px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    img {
      width: 19px;
      height: 21px;
      color: #414855;
    }

    button {
      width: 119px;
      height: 40px;

      @media (min-width: $bp-large-min) {
        width: 183px;
        height: 49px;
      }
    }
  }

  &__actions-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 12px;

    @media (min-width: $bp-large-min) {
      margin: 0 28px;
    }

    span {
      padding: 0 7px;
      color: #414855;
      font-family: 'Co-Headline';
      font-weight: 700;
      font-size: 12px;
      cursor: pointer;
      text-transform: none;

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        font-size: 14px;
      }
    }

    .tooltip-show {
      width: 67px;
      background-color: black;
      color: #fff;
      direction: ltr;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: $z-one;
      font-size: 10px;
      font-weight: 400;
      margin-top: 55px;
      margin-left: 24px;
      margin-right: 24px;
      line-height: 1;
    }

    .tooltip-hide {
      display: none;
    }

    /* button{
            display: flex;
            cursor: pointer;
            border: unset;
            background: unset;
            width: auto;
            height: auto;
            padding: 0;
            margin: 0;
        }*/
  }

  &__bg {
    width: 100%;
    height: 70vh;
    object-fit: cover;

    @media (min-width: $bp-large-min) {
      height: 80vh;
    }
  }

  &__shadow {
    &:before {
      height: 70vh;
      content: ' ';
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      position: absolute;
      z-index: 2;
      display: block;
      @media (min-width: $bp-large-min) {
        height: 80vh;
      }
    }
  }

  &__related-news {
    margin-top: -32vh;

    @media (min-width: $bp-large-min) {
      margin-top: -15vh;
    }
  }

  &__signature {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #47ad42;
    justify-content: center;
    margin-bottom: 50px;
    html[dir='rtl'] & {
      font-family: 'Tajawal';
      text-align: right;
      border-right: 2px solid #47ad42;
      border-left: unset;
    }

    &--name {
      padding: 0 10px;
      font-family: 'Co-Headline';
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.04em;
      @media (max-width: $bp-medium-min) {
        font-size: 20px;
      }
      html[dir='rtl'] & {
        font-family: 'Tajawal';
        text-align: right;
      }
    }

    &--position {
      padding: 0 10px;
      font-family: 'Calibri';
      font-size: 22px;
      font-weight: 400;
      line-height: 1.6363;
      letter-spacing: 0.02em;
      @media (max-width: $bp-medium-min) {
        font-size: 16px;
      }
    }
  }
}

.articleBody {
  img {
    object-fit: cover;
  }
}
