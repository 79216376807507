.latest-news {
  padding: 115px 0;
  margin-left: -$column-gutter;
  margin-right: -$column-gutter;
  padding-bottom: 0;

  @media (min-width: $bp-medium-min) {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 115px;
  }

  &__page {
    padding-top: 30px;

    @media (min-width: $bp-medium-min) {
      padding-top: 50px;
    }
  }

  &__related-news {
    padding-bottom: 0;
    padding-top: 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $column-gutter;
    // margin-bottom: 36px;
    color: #fff;
    font-family: 'Co-Headline';
    align-items: end;
    padding-left: $column-gutter;
    padding-right: $column-gutter;

    @media (min-width: $bp-medium-min) {
      margin-bottom: 46px;
      padding-left: 0;
      padding-right: 0;
    }

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 1.284;

      @media (min-width: $bp-medium-min) {
        font-size: 36px;
        line-height: 1.284;
      }
    }

    &--all-news {
      border-bottom: 1px solid #fff;
      color: #fff;
      font-family: 'Co-Headline';
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      justify-self: end;
      padding-bottom: 6px;
      text-decoration: none;
    }

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }
  }

  &__wrapper {
    padding-top: 36px;
    display: grid;
    grid-template-columns: 1fr;
    gap: $column-gutter;

    padding-left: $column-gutter;
    padding-right: $column-gutter;

    @media (min-width: $bp-medium-min) {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $bp-large-min) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .latest-news__page & {
      padding-top: 0;
    }

    @include rtl-direction;
  }

  &__load-more {
    text-align: center;
    margin-top: 40px;

    @media (min-width: $bp-medium-min) {
      margin-top: 50px;
    }

    .btn {
      width: 119px;

      @media (min-width: $bp-medium-min) {
        width: 183px;
      }
    }
  }

  @include rtl-direction;
}

.news-item {
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 14px;
  padding: 20px 20px 30px;
  transition: box-shadow 0.4s ease;

  .swiper-slide & {
    height: 100%;
  }

  &__date {
    color: $c-half-baked;
    font-family: 'Co-Headline';
    font-size: 12px;
    font-weight: 700;
    line-height: 1.75;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
      font-weight: 700;
    }
  }

  &__image {
    border-radius: 14px 14px 0 0;
    background: rgba(0, 0, 0, 0.15);
    aspect-ratio: 43 / 26;
    margin: -20px -20px 25px;
    clip-path: ellipse(200% 200% at 100% 100%);
    transition: clip-path 0.25s ease;

    img,
    a {
      border-radius: 14px 14px 0 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    background: linear-gradient(270.17deg, #ed6940 50%, #bc204b 98.6%);
    @include text-clip;
    font-family: 'Co-Headline';
    font-size: 22px;
    font-weight: 400;
    line-height: 1.455;
    margin-bottom: 15px;
    text-transform: capitalize;

    a {
      text-decoration: none;
    }

    html[dir='rtl'] & {
      background: linear-gradient(270.19deg, #ed4440 15%, #bc204b 35.51%);
      @include text-clip;
      font-family: 'Tajawal';
      font-weight: 500;
      font-size: 24px;
      line-height: 1.4;
    }
  }

  &:hover {
    box-shadow: 0px 30px 20px -15px rgba(0, 0, 0, 0.5);

    .news-item__image {
      clip-path: ellipse(90% 56% at 43% 44%);
    }
  }

  p {
    html[dir='rtl'] & {
      font-size: 16px;
      line-height: 1.375;
    }
  }

  @media (max-width: $bp-medium-max) {
    .swiper-slide-active & {
      box-shadow: 0px 30px 20px -15px rgba(0, 0, 0, 0.5);

      .news-item__image {
        clip-path: ellipse(90% 56% at 43% 44%);
      }
    }
  }
}

.latest-news .swiper-backface-hidden {
  .swiper-slide-active {
    transform: scale(1.05) translateZ(0);

    @media (min-width: $bp-medium-min) {
      transform: translateZ(0);
    }
  }
}

.featured-news {
  position: relative;
  height: 80vh;
  width: 100%;

  &__photo {
    aspect-ratio: 2 / 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-one;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      content: ' ';
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: $z-one * 2;
      display: block;
    }
  }

  &__date {
    font-family: 'Co-Headline';
    font-weight: 700;
    font-size: 14px;
    line-height: 1.285;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }

    @media (min-width: $bp-medium-min) {
      font-size: 18px;
      line-height: 1.75;
      margin-bottom: -5px;
    }
  }

  &__content {
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    position: relative;
    z-index: $z-one;

    &-wrapper {
      z-index: $z-medium;

      @media (min-width: $bp-medium-min) {
        max-width: 700px;
      }

      * {
        position: relative;
        // z-index: $z-medium;
      }

      .icon {
        height: 100px;
        left: -30px;
        bottom: -65px;

        @media (min-width: $bp-medium-min) {
          height: 120px;
          bottom: -95px;
        }

        @media (min-width: $bp-navigation-min) {
          height: auto;
          left: -40px;
        }

        html[dir='rtl'] & {
          left: auto;
          right: -30px;

          @media (min-width: $bp-navigation-min) {
            left: auto;
            right: -40px;
          }
        }
      }
    }

    h1 {
      font-family: 'Co-Headline';
      font-size: 32px;
      font-weight: 400;
      line-height: 1.3125;
      margin-bottom: 10px;

      @media (min-width: $bp-medium-min) {
        font-size: 52px;
        line-height: 1.1923;
        margin-bottom: 20px;
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }
    }

    p {
      font-size: 18px;
      line-height: 1.222;
      margin-bottom: 24px;

      @media (min-width: $bp-medium-min) {
        font-size: 24px;
        line-height: 1.333;
        margin-bottom: 40px;
      }
    }

    .btn {
      width: 168px;
      @media (min-width: $bp-medium-min) {
        width: 190px;
      }
    }
  }

  html[dir='rtl'] & {
    font-family: 'Tajawal';
  }
}
