/** Calibri - English */
@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/Calibri-Italic.woff2') format('woff2'),
    url('../../fonts/Calibri-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/Calibri-BoldItalic.woff2') format('woff2'),
    url('../../fonts/Calibri-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/Calibri-Bold.woff2') format('woff2'),
    url('../../fonts/Calibri-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/Calibri-LightItalic.woff2') format('woff2'),
    url('../../fonts/Calibri-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/Calibri-Light.woff2') format('woff2'),
    url('../../fonts/Calibri-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../fonts/Calibri.woff2') format('woff2'),
    url('../../fonts/Calibri.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/** CoHeadline - English */
@font-face {
  font-family: 'Co-Headline';
  src: url('../../fonts/Co-Headline.woff') format('woff'),
    url('../../fonts/Co-Headline.woff2') format('woff2'), url('../../fonts/Co-Headline.eot'),
    url('../../fonts/Co-Headline.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Co-Headline.otf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Co-Headline';
  src: url('../../fonts/Co-Headline-Bold.woff') format('woff'),
    url('../../fonts/Co-Headline-Bold.woff2') format('woff2'),
    url('../../fonts/Co-Headline-Bold.eot'),
    url('../../fonts/Co-Headline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Co-Headline-Bold.otf') format('truetype');

  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Co-Headline';
  src: url('../../fonts/Co-Headline-Light.woff') format('woff'),
    url('../../fonts/Co-Headline-Light.woff2') format('woff2'),
    url('../../fonts/Co-Headline-Light.eot'),
    url('../../fonts/Co-Headline-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Co-Headline-Light.otf') format('truetype');

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/** Tajawal - Arabic */
@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal-Bold.woff2') format('woff2'),
    url('../../fonts/Tajawal-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal-Black.woff2') format('woff2'),
    url('../../fonts/Tajawal-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal-ExtraBold.woff2') format('woff2'),
    url('../../fonts/Tajawal-ExtraBold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal-Light.woff2') format('woff2'),
    url('../../fonts/Tajawal-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal-ExtraLight.woff2') format('woff2'),
    url('../../fonts/Tajawal-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal-Medium.woff2') format('woff2'),
    url('../../fonts/Tajawal-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal-Regular.woff2') format('woff2'),
    url('../../fonts/Tajawal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KSP-Medium';
  src: url('../../fonts/KSP-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
