.vertical-carousel {
  @media (min-width: $bp-large-min) {
    height: 100vh;
  }

  .swiper,
  &__wrapper {
    height: 100%;
  }

  .swiper {
    padding-bottom: 0;
  }

  .swiper-slide {
    height: auto;
  }

  &__slide {
    height: 100%;
    position: relative;

    &-image {
      aspect-ratio: 2 / 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $z-one;

      &:before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        background: rgba(0, 0, 0, 0.25);
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      height: 100%;
      //padding: 35px 0;
      position: relative;
      z-index: $z-low;
      opacity: 0;
      transition: opacity ease-in-out 1s;

      .swiper-slide-active & {
        opacity: 1;
      }

      .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: 100%;
        padding-top: 190px;
      }

      &--inner {
        color: #fff;
        max-width: 530px;
      }

      h3 {
        font-size: 14px;
        line-height: 1.221;
      }

      h2 {
        font-family: 'Co-headline';
        font-size: 48px;
        font-weight: 400;
        line-height: 2;

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }

      p {
        font-size: 24px;
        line-height: 1.333;
        + p {
          margin-top: 48px;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    left: 50px;
    right: auto;
  }
  .swiper-pagination {
    width: calc(100% - 30px);
    top: auto;
    bottom: 60px;
    left: 15px;
    right: 15px;
    transform: translate3d(0, 0, 0);

    .swiper-pagination-bullet {
      display: inline-block;
      margin: 0 var(--swiper-pagination-bullet-vertical-gap, 4px);
      width: 182px;
    }
  }

  .accordion__item {
    background-size: cover;
    border: none;
    color: #fff;
    position: relative;

    &:before {
      content: ' ';
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: $z-one * 2;
      display: block;
    }

    .accordion__heading {
      position: relative;
      z-index: $z-one * 3;

      .accordion__button {
        padding: 30px 20px;
        font-size: 26px;
        line-height: 1.284;

        &--collapse {
          display: block;
        }

        &--expand {
          display: none;
        }

        &[aria-expanded='true'] {
          .accordion__button--collapse {
            display: none;
          }

          .accordion__button--expand {
            display: block;
          }
        }
      }
    }

    .accordion__panel {
      padding: 0 20px 30px;
      z-index: $z-one * 3;
      position: relative;

      p {
        font-size: 18px;
        line-height: 1.222;
        + p {
          margin-top: 25px;
        }
      }
    }
  }
}
