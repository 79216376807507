.features {
  background: rgba(0, 0, 0, 0.4);

  &__wrapper {
    direction: ltr;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;

    @media (min-width: $bp-large-min) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    flex: 1;

    img {
      flex: 1;
      object-fit: cover;
      background: rgba(0, 0, 0, 0.4);
      mix-blend-mode: normal;
    }

    &--img-overlay {
      &::before {
        background: rgba(0, 0, 0, 0.4);
        mix-blend-mode: normal;
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &--container {
      position: relative;
      width: 100%;
      text-align: center;
      color: white;
      font-size: 36px;
      font-weight: 400;
      font-family: 'Co-Headline';
      flex: calc(100% / 3);

      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }

      @media (max-width: $bp-large-min) {
        flex: calc(100% / 1);
      }

      .text {
        position: absolute;
        top: 61%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        div {
          font-family: 'Co-Headline';
          font-size: 36px;
          color: white;
          text-align: center;

          html[dir='rtl'] & {
            font-family: 'Tajawal';
          }
        }

        p {
          color: #ffffff;
          font-family: 'Calibri';
          margin-top: 10px;
          font-size: 18px;
          min-width: 280px;

          html[dir='rtl'] & {
            font-family: 'Tajawal';
          }
        }

        button {
          font-size: 14px;
          font-weight: 700;
          font-family: 'Co-Headline';
          color: white;
          border: unset;
          background: unset;
          cursor: pointer;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 25px;

          html[dir='rtl'] & {
            flex-direction: row-reverse;
            font-family: 'Tajawal';
          }

          div {
            font-size: 14px;
            font-weight: 700;
            font-family: 'Co-Headline';
            color: white;

            html[dir='rtl'] & {
              font-family: 'Tajawal';
            }
          }

          i {
            margin: 0 12px;
          }

          &:hover {
            div {
              color: #47ad42;
            }

            i {
              margin: 0 12px;
              svg {
                fill: white;
                stroke: #47ad42;

                path {
                  fill: #47ad42;
                }
              }
            }
          }
        }
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
}

.modal-video-body {
  max-width: 100% !important;
}

.modal-video-close-btn {
  cursor: pointer;
}

.feature-container {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;

  &__outdoor__green {
    background: rgb(11 87 64 / 70%);
  }

  &__energetic__red {
    background: #be214b;
  }

  &__bright__turquoise {
    background: rgb(135 204 202 / 70%);
  }

  &__spontaneous__yellow {
    background: #f3dc46;
  }

  &__deep__sea__blue {
    background: #0076cd;
  }

  &__slate__grey {
    background: rgb(130 145 165 / 70%);
  }

  &__social__orange {
    background: #ff6a3c;
  }

  &__vibrant__green {
    background: #74d651;
  }
}

.feature-container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 61%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
