.slideshow-carousel {
  min-height: 100vh;

  .swiper,
  &__wrapper {
    height: 100%;
  }

  .swiper {
    padding-bottom: 0;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination {
    margin-bottom: 5px;
  }

  &__slide {
    height: 100%;
    position: relative;

    &-image {
      aspect-ratio: 2 / 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $z-one;

      &:before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        background: rgba(0, 0, 0, 0.25);
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      height: 100%;
      padding: 35px 0;
      position: relative;
      z-index: $z-low;

      @media (min-width: $bp-large-min) {
        padding: 80px 0;
      }

      .container {
        height: calc(100vh - 70px);
        @media (min-width: $bp-large-min) {
          height: 100vh;
        }
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        direction: ltr;
      }
    }

    &-card {
      background: rgb(11 87 64 / 50%);
      transition: all 0.5s ease-in-out;
      border-radius: 14px;
      color: #fff;
      max-width: 510px;
      padding: 10px 20px;

      @media (min-width: $bp-large-min) {
        padding: 30px;
      }

      h2 {
        font-family: 'Co-headline';
        font-weight: 400;
        font-size: 26px;
        line-height: 1.741;
        margin-bottom: 8px;

        @media (min-width: $bp-large-min) {
          font-size: 36px;
          line-height: 1.722;
          margin-bottom: 15px;
        }

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }

      p {
        font-size: 14px;
        line-height: 1.428;

        @media (min-width: $bp-large-min) {
          font-size: 18px;
          line-height: 1.722;
        }

        &:first-child {
          font-weight: 700;
        }
        & + p {
          margin-top: 30px;
        }
      }

      ul {
        margin: 0 0 0 -1em;
      }

      &--highlights {
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 120px;
        font-size: 20px;
        line-height: 1.2;
        height: 200px;
        margin-top: 30px;

        @media (min-width: $bp-large-min) {
          background-size: auto 75%;
          font-size: 28px;
          height: 300px;
          line-height: 1.221;
          margin-top: 40px;

          html[dir='rtl'] & {
            margin-top: 20px;
            background-size: 140px;
          }
        }

        h3 {
          font-family: 'Co-headline';
          font-weight: 300;
          font-size: 36px;
          margin-bottom: 10px;

          @media (min-width: $bp-large-min) {
            font-size: 66px;

            html[dir='rtl'] & {
              line-height: 1;
            }
          }

          html[dir='rtl'] & {
            font-family: 'Tajawal';
          }
        }

        html[dir='rtl'] & {
          background-position: bottom left;
        }
      }

      &.card-collapsed {
        background: transparent;
      }

      &--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 18px;

        @media (min-width: $bp-large-min) {
          margin-bottom: 25px;
        }

        h2 {
          flex: auto;
          transition: flex 0.5s linear;

          .card-collapsed & {
            flex: none;
          }
        }

        .btn-collapse {
          margin-left: 18px;

          html[dir='rtl'] & {
            margin-left: 0;
            margin-right: 18px;
          }
        }
      }

      &--content {
        max-height: 100vh;
        overflow: hidden;
        transition: max-height 0.5s linear;

        &-inner {
          transition: all 0.7s ease-in-out;
          line-height: 1.5;
          margin-top: 0;
        }

        .card-collapsed & {
          max-height: 0;

          &-inner {
            margin-top: 100%;
          }
        }
      }

      html[dir='rtl'] & {
        direction: rtl;
      }
    }

    &-cardless {
      color: #fff;
      display: flex;
      height: calc(100vh - 70px);
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      text-align: center;

      @media (min-width: $bp-large-min) {
        align-items: center;
        height: 100vh;
      }

      &--content {
        position: relative;
        padding: 70px $column-gutter 0;

        @media (min-width: $bp-large-min) {
          padding: 0;
        }

        img {
          margin: 0 auto;
          max-width: 110px;
          height: auto;
          z-index: $z-one;

          @media (min-width: $bp-large-min) {
            position: absolute;
            left: -55px;
            top: -75px;
          }
        }
      }

      h2 {
        font-family: 'Co-headline';
        font-weight: 700;
        font-size: 64px;
        line-height: 1.1875;
        position: relative;
        z-index: $z-low;

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }
    }
  }
}

.slideshow-carousel--full-card,
.slideshow-carousel--full-card-flipped {
  background: #b43f5e;
  min-height: unset;

  @media (min-width: $bp-large-min) {
    min-height: 100vh;
  }

  .swiper,
  &__wrapper {
    @media (min-width: $bp-large-min) {
      height: 100vh;
    }
  }

  .slideshow-carousel__slide {
    &-content {
      color: #fff;
      padding: 0;

      .container {
        height: auto;
      }
    }

    &-image {
      &:after {
        background: rgba($color: rgb(17 87 64 / 50%), $alpha: 0.75);
        content: '';
        height: 100vh;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: $z-low;
        transition: all 0.5s ease-in-out;

        /*@media (min-width: $bp-navigation-min) {
          width: calc(50% - 10px);
        }*/
      }

      &.card-collapsed {
        &:after {
          background: transparent;
        }
      }
    }

    &-card {
      background: transparent;
      border-radius: 0;
      max-width: unset;
      padding: 0;

      &--header {
        h2 {
          font-family: 'Co-headline';
          font-weight: 400;
          font-size: 32px;
          line-height: 1.741;

          @media (min-width: $bp-large-min) {
            font-size: 36px;
            line-height: 1.722;
          }

          html[dir='rtl'] & {
            font-family: 'Tajawal';
          }
        }
      }

      p {
        @media (min-width: $bp-large-min) {
          font-size: 22px;
          line-height: 1.722;
        }
      }
    }

    &-content--inner {
      padding: 35px 0 50px;

      @media (min-width: $bp-large-min) {
        padding: 100px 0 0;
        max-width: 460px;
      }

      ul {
        margin: 0 0 0 -1em;
      }

      html[dir='rtl'] & {
        direction: rtl;
      }

      table {
        border: none;
        height: auto !important;
        width: 100% !important;
        margin: 15px 0;

        tr {
          & + tr td {
            padding-top: 10%;
          }
        }

        td {
          text-align: center !important;
          vertical-align: top !important;
          img {
            display: block;
            margin: 0 auto !important;
            max-height: 100px;
            width: auto !important;
          }
        }
      }

      a {
        color: #fff;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    &-image--mobile {
      aspect-ratio: 67 / 90;
      height: 450px;
      margin-bottom: 25px;
      width: 100%;

      @media (min-width: $bp-large-min) {
        margin-bottom: 40px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.slideshow-carousel--full-card {
  .slideshow-carousel__slide {
    &-content {
      .container {
        direction: ltr;
      }
    }
  }
  .swiper-pagination {
    @media (min-width: $bp-large-min) {
      width: 50%;
      left: 50%;
    }

    @media (min-width: 1420px) {
      width: calc(100% - 700px);
      left: 700px;
    }
  }
}

.slideshow-carousel--full-card-flipped {
  background: #8bbfbd;

  .slideshow-carousel__slide {
    &-content {
      @media (min-width: $bp-large-min) {
        .container {
          display: flex;
          flex-direction: row-reverse;

          html[dir='rtl'] & {
            flex-direction: row;
          }
        }
      }
    }

    &-image {
      &:after {
        background: rgba($color: #87ccca, $alpha: 0.75);
        left: auto;
        right: 0;
      }

      &.card-collapsed {
        &:after {
          background: transparent;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    left: 50px;
    right: auto;
  }
  .swiper-pagination {
    @media (min-width: $bp-large-min) {
      width: 50%;
    }

    @media (min-width: 1420px) {
      width: calc(100% - 700px);
    }
  }
}
