.social-links {
  direction: ltr;
  justify-self: end;
  margin: 10px 0 0;
  padding: 0;
  li {
    display: inline-block;
    list-style: none;
    + li {
      padding-left: 25px;
    }
    a {
      display: inline-block;
      text-decoration: none;

      span {
        display: inline-block;
      }

      svg {
        path {
          transition: all 0.4s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            fill: $c-maroon-flush;
          }
        }
      }
    }
  }

  html[dir='rtl'] & {
    text-align: right;
  }
}
