body {
  color: $c-outer-space;
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.333;

  html[dir='rtl'] & {
    font-family: 'Tajawal';
  }

  &.has-overlay {
    overflow: hidden;
  }
}
