@import '../abstracts/variables';
@import '../abstracts/breakpoints';

header {
  direction: ltr;
  font-family: 'Co-Headline';
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: $z-low;

  @media (min-width: $bp-large-min) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .container {
    max-width: $bp-navigation-min;
  }

  html[dir='rtl'] & {
    font-family: 'Tajawal';
  }

  &.editor {
    position: static;
  }
}

.header__wrapper {
  display: flex;
  justify-content: flex-start;

  .header__menu-open,
  .header__language-toggler {
    flex: 0 1 auto;
  }

  .header-logo {
    flex: 2 0 auto;
    display: flex;
    justify-content: flex-end;
  }
}

.header-logo {
  /*flex: 0 1 auto;*/
  img {
    height: 46px;

    @media (min-width: $bp-large-min) {
      height: 76px;
    }
  }
}

.header__language-toggler {
  display: flex;
  align-items: center;
  padding-left: 28px;
  font-family: 'Co-Headline';
  width: 110px;

  @media (min-width: $bp-large-min) {
    padding-left: 38px;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    /* Hide default HTML checkbox */
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: $c-atlantis;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: $c-atlantis;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $c-atlantis;
  }

  input:checked + .slider:before {
    background-color: #fff;
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .switch-label {
    color: #fff;
    position: relative;
    left: 40px;
    top: -3px;
    transition: none;
    box-shadow: none !important;
  }
}

.header__menu-open,
.header__menu-close {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

.header__menu-overlay {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  // height: 100%;
  height: 100vh;
  width: 100%;
  z-index: $z-v-high;
  display: none;
  &.is-open {
    display: block;
  }
}

.header__menu-container {
  background: url(../../img/menu-bg.png) center no-repeat rgba(17, 87, 64, 0.5);
  background-size: cover;
  backdrop-filter: blur(10px);
  height: 100vh;
  padding: 20px;
  position: fixed;
  left: -100%;
  top: 0;
  width: 100%;
  transition: left 0.4s;
  z-index: $z-v-high * 2;

  @media (min-width: $bp-medium-min) {
    /*left: -420px;*/
    width: 420px;
    padding: 28px 0px 50px 50px;
    html[dir='rtl'] & {
      font-family: 'Tajawal';
      padding: 28px 50px 50px 20px;
    }
  }

  @media (max-width: $bp-medium-min) {
    padding: 20px 10px 20px 20px;
  }

  &.is-open {
    left: 0;
  }

  .header__menu-links {
    margin: 90px 0 0;
    padding: 0;
    li {
      list-style: none;
      + li {
        margin-top: 46px;
        @media (max-width: $bp-medium-min) {
          margin-top: 21px;
        }
      }

      a {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        //line-height: 1;
        text-decoration: none;
        text-transform: capitalize;

        &:hover,
        &.active {
          font-weight: 700;
        }

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }
      .no-link {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        //line-height: 1;
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    @include rtl-direction;
  }

  .header__social-links {
    .social-links {
      bottom: 20px;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      position: absolute;
      z-index: $z-one;

      @media (min-width: $bp-medium-min) {
        bottom: 50px;
      }

      html[dir='rtl'] & {
        right: 20px;

        @media (min-width: $bp-medium-min) {
          right: 50px;
        }
      }
    }
  }
}

[dir='rtl'] .menu-item {
  &__arrow-right {
    .arrow-menu::after {
      display: none;
    }
    .arrow-menu::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 12px;
      background: url(../../../../src/assets/img/right-arrow-white.svg) center no-repeat;
      background-size: cover;
      margin-left: 5px;
      margin-top: 10px;
    }
  }
}
.menu-item {
  position: relative;

  &__arrow-right {
    .arrow-menu {
      display: flex;
      align-items: center;
    }

    .arrow-menu::after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 12px;
      background: url(../../../../src/assets/img/right-arrow-white.svg) center no-repeat;
      background-size: cover;
      margin-left: 5px;
      margin-top: 10px;
    }
  }

  &__arrow-collapse {
    .collapse-mobile::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(../../../../src/assets/img/plus-collapse.svg) center no-repeat;

      background-size: cover;
      margin-left: 5px;
      margin-top: 10px;
    }
  }

  &__arrow-collapse-close {
    .collapse-mobile::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(../../../../src/assets/img/minus-collapse.svg) center no-repeat;
      background-size: cover;
      margin-left: 5px;
      margin-top: 10px;
    }
  }
}

.menu-item:hover .sub-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 125%;
  height: 100vh;
  padding: 20px;
  top: 0;
  width: 100%;
  transition: left 0.4s;
  z-index: $z-v-high;
  padding: 0;
  height: 100vh;
  width: 100%;

  html[dir='rtl'] & {
    left: 80%;
  }
    
  &__sub-bg {
    position: fixed;
    background: rgba(17, 87, 64, 0.5);
    background-size: cover;
    backdrop-filter: blur(40px);
    min-height: 100vh;
    top: 0px;
    left: 100%;
    width: 100%;
  }

  &__text-sub {
    font-family: 'Co-Headline';
    font-size: 22px;
    font-weight: 300;
  }

  li {
    position: relative;
  }
}

.sub-menu {
  display: none;
}

.mobile-view-menu {
  @media (min-width: $bp-medium-min) {
    display: none;
  }

  &__btn-collapse {
    border: unset;
    background: unset;
    color: white;
    padding: 0;
    font-size: 22px;
    line-height: 1;
  }
}

.desktop-view-menu {
  @media (max-width: $bp-medium-min) {
    display: none;
  }
}

.sub-menu-mobile.open {
  display: block;
}

.sub-menu-mobile {
  display: none;

  &__list-container {
    padding: 20px 0;
  }
}
.header__search-link {
  flex: 0 1 auto;
  display: flex;
  align-self: center;
  margin-left: 50px;
}

.header__search {
  border-bottom: 1px solid #fff;
  bottom: 100px;
  padding-bottom: 15px;
  position: absolute;
  width: calc(100% - 40px);

  @media (min-width: $bp-medium-min) {
    width: calc(100% - 100px);
  }

  .header__search-input {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 15px);

    input {
      background: transparent;
      font-family: 'Co-Headline';
      font-weight: 300;
      font-size: 22px;
      line-height: 1;
      border: none;
      color: #fff;
      outline: none;
      margin: 0 0 0 15px;
      padding: 0;
      width: calc(100% - 64px);

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        margin: 0 15px 0 0;
      }

      &:focus,
      &:focus-visible {
        border: none;
        outline: none;
      }

      &::placeholder {
        font-family: 'Co-Headline';
        color: #fff;

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }
    }
  }

  html[dir='rtl'] & {
    direction: rtl;
  }

  .header__search-btn {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    height: 36px;
    padding: 8px;
    width: 36px;
    text-align: center;
    display: flex;
    align-items: center;

    html[dir='rtl'] & {
      svg {
        transform: rotateY(180deg);
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}
