@import './../abstracts/mixins';

$breakpoint-mobile: 768px;

.events {
  margin: 60px auto 35px auto;

  html[dir='rtl'] & {
    margin: 111px auto -20px auto;

    @media (max-width: $breakpoint-mobile) {
      margin: 20px auto 20px auto;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    margin: 20px auto 20px auto;
  }
  &__box {
    display: flex;
    flex-direction: column;

    img {
      height: 240px !important;
      width: 207px !important;
      position: absolute;
      top: 122px;
      z-index: -1;
      @media (max-width: $breakpoint-mobile) {
        width: 62px !important;
        height: 72px !important;
        top: 63px;
        z-index: -1;
        left: -10px;
        html[dir='rtl'] & {
          right: -10px;
        }
      }
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    gap: 55px;
    margin-bottom: 12px;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      gap: 5px;
    }
  }

  &__title {
    font-family: 'Co-Headline';
    font-size: 22px;
    font-weight: 400;
    line-height: 1.27272;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 6px;

    html[dir='rtl'] & {
      text-align: right;
    }
  }

  &__date {
    font-family: 'Co-Headline';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: 0px;
    text-align: left;
  }

  &__location {
    font-family: 'Co-Headline';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: 0px;
    text-align: left;
    @media (max-width: $breakpoint-mobile) {
      margin-left: 21px;
      html[dir='rtl'] & {
        font-family: 'Tajawal';
        margin-left: 21px;
      }
    }

    &:before {
      content: '';
      background: url(../../img/location-event.svg) center bottom no-repeat;
      position: absolute;
      width: 18px;
      height: 23px;
      margin-left: -24px;
      margin-top: -3px;

      @media (max-width: $breakpoint-mobile) {
        margin-left: -22px;
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        margin-right: -24px;
      }
    }
  }

  &__location-green {
    font-family: 'Co-Headline';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: 0px;
    text-align: left;
    @media (max-width: $breakpoint-mobile) {
      margin-left: 21px;
      html[dir='rtl'] & {
        font-family: 'Tajawal';
        margin-left: 21px;
      }
    }

    &:before {
      content: '';
      background: url(../../img/location-green-event.svg) center bottom no-repeat;
      position: absolute;
      width: 18px;
      height: 23px;
      margin-left: -24px;
      margin-top: -3px;

      @media (max-width: $breakpoint-mobile) {
        margin-left: -22px;
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        margin-right: -24px;
      }
    }
  }

  &__desc {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3333;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 25px;

    html[dir='rtl'] & {
      text-align: right;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 30px;

    &--btnBook {
      font-size: 12px;
      font-family: 'Co-Headline';
      font-weight: 700;
      width: 146px;
      height: 48px;
      border-radius: 100px;
      color: #ffffff;
      background: linear-gradient(265.9deg, #ed6940 0%, #bc204b 100%);
      border: unset;
      cursor: pointer;
    }

    &--btncontact {
      font-size: 12px;
      font-family: 'Co-Headline';
      font-weight: 700;
      width: 146px;
      height: 48px;
      border-radius: 100px;
      color: #bc204b;
      border: 2px solid #bc204b;
      background: unset;
      cursor: pointer;
    }
  }
}
