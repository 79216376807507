.search {
  &__input-wrapper {
    padding-top: 40px;
  }

  &__input-field {
    background: #fff;
    border: 1px solid rgba(130, 145, 165, 0.4);
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 6px 17px;
    width: 100%;

    @media (min-width: $bp-large-min) {
      padding: 21px 35px;
    }

    input {
      font-family: 'Co-Headline';
      font-weight: 300;
      font-size: 20px;
      line-height: 1.5;
      margin: 0 0 0 15px;
      width: calc(100% - 43px);
      border: none;
      outline: none;

      @media (min-width: $bp-large-min) {
        font-size: 24px;
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        margin: 0 15px 0 0;
      }

      &:focus,
      &:focus-visible {
        border: none;
        outline: none;
      }

      &::placeholder {
        font-family: 'Co-Headline';
        color: $c-outer-space;

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }
    }
  }

  &__header {
    margin: 30px 0;

    @media (min-width: $bp-large-min) {
      margin: 40px 0 80px;
    }

    h2 {
      background: linear-gradient(270.17deg, #ed6940 50%, #bc204b 98.6%);
      font-family: 'Co-Headline';
      font-weight: 400;
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 25px;
      @include text-clip;

      @media (min-width: $bp-large-min) {
        font-size: 64px;
        margin-bottom: 40px;
      }

      html[dir='rtl'] & {
        background: linear-gradient(270.19deg, #ed4440 15%, #bc204b 35.51%);
        @include text-clip;
        font-family: 'Tajawal';
        line-height: 1.4;
      }
    }

    &-filters {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;

      @media (min-width: $bp-large-min) {
        margin-bottom: 30px;
      }

      .btn {
        border: 1px solid #d9d9d9;
        background: transparent;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.286;
        padding: 5px 15px;
        text-transform: none;
        white-space: nowrap;

        @media (min-width: $bp-large-min) {
          padding: 10px 22px;
        }

        &-selected {
          border-color: transparent;
          background: linear-gradient(265.9deg, #ed6940 0%, $c-maroon-flush 100%);
          color: #fff;
          font-weight: 400;
        }

        html[dir='rtl'] & {
          font-weight: 400;
        }
      }
    }
  }

  &__results-wrapper {
    margin-bottom: 60px;
    &.hidden {
      display: none;
    }

    .latest-news__wrapper {
      margin-bottom: -30px;
      padding: 30px 0 80px;

      @media (min-width: $bp-large-min) {
        margin-bottom: 0;
        padding: 30px 0 50px;
      }

      .swiper-pagination-bullet {
        b {
          animation-name: none;
        }
      }

      .swiper-pagination-bullet-active {
        b {
          width: 100%;
        }
      }
    }
  }

  &__results-title {
    background: linear-gradient(270.17deg, #ed6940 90%, #bc204b 98.6%);
    font-family: 'Co-Headline';
    font-weight: 400;
    font-size: 24px;
    line-height: 1.6;
    @include text-clip;

    @media (min-width: $bp-large-min) {
      font-size: 48px;
    }

    html[dir='rtl'] & {
      background: linear-gradient(270.19deg, #ed4440 15%, #bc204b 35.51%);
      @include text-clip;
      font-family: 'Tajawal';
    }
  }

  &__result-item {
    border-bottom: 1px solid rgba(130, 145, 165, 0.4);
    padding: 30px 0;

    &-title {
      font-family: 'Co-Headline';
      font-weight: 400;
      font-size: 20px;
      line-height: 1.284;
      margin-bottom: 20px;

      @media (min-width: $bp-large-min) {
        font-size: 22px;
      }

      a {
        color: $c-outer-space;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }
    }
  }

  &__no-results {
    font-family: 'Co-Headline';
    font-size: 21px;
    margin-top: 30px;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }
  }
}
