footer {
  direction: ltr;
  font-size: 14px;
  line-height: 1.221;
  padding-top: 30px;
  padding-bottom: 25px;

  @media (min-width: $bp-large-min) {
    font-size: 18px;
    line-height: 1.333;
    padding-top: 95px;
  }

  hr {
    @include hr;
  }
}
.footer {
  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $column-gutter;

    @media (min-width: $bp-large-min) {
      @include grid-12-cols;
    }

    &--find-us {
      @media (min-width: $bp-large-min) {
        grid-column: 1 / 5;
      }

      @include rtl-direction;
    }

    &--contact-us {
      @media (min-width: $bp-large-min) {
        grid-column: 5 / 9;
      }

      @include rtl-direction;
    }

    h2 {
      background: linear-gradient(268.65deg, #78d64b 45%, #115740 99.05%);
      font-family: 'Co-Headline';
      font-weight: 400;
      font-size: 18px;
      line-height: 1.284;
      @include text-clip;

      @media (min-width: $bp-medium-min) {
        background: linear-gradient(268.65deg, #78d64b 75%, #115740 99.05%);
        @include text-clip;
      }

      @media (min-width: $bp-large-min) {
        font-size: 22px;
        line-height: 1.727;
      }

      html[dir='rtl'] & {
        background: linear-gradient(88.65deg, #115740 45%, #78d64b 99.05%);
        font-family: 'Tajawal';
        font-weight: 500;
        @include text-clip;

        @media (min-width: $bp-medium-min) {
          background: linear-gradient(88.65deg, #115740 75%, #78d64b 99.05%);
          @include text-clip;
        }
      }
    }

    a {
      color: $c-outer-space;
      text-decoration: none;
      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }

  &__logo {
    justify-self: end;
    @media (min-width: $bp-large-min) {
      grid-column: 9 / 13;
    }

    img {
      height: 52px;

      @media (min-width: $bp-small-min) {
        height: auto;
      }

      @media (min-width: $bp-large-min) {
        height: 93px;
      }
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $column-gutter;
    align-items: center;
    margin-top: 30px;

    @media (min-width: $bp-large-min) {
      margin-top: 0;
    }
  }

  &__copyrights {
    font-size: 14px;
    line-height: 2;
  }

  &__social-links {
    display: flex;
    justify-self: end;
    .social-links {
      margin: 10px 0 0;

      @media (min-width: $bp-large-min) {
        margin-top: 0;
      }

      li {
        + li {
          padding-left: 10px;

          @media (min-width: $bp-large-min) {
            padding-left: 25px;
          }
        }
      }
    }
  }

  &__other-links {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      list-style: none;
      + li {
        @media (min-width: 410px) {
          padding-left: 10px;
        }
        @media (min-width: $bp-large-min) {
          padding-left: 25px;
        }
      }

      a {
        color: $c-outer-space;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
