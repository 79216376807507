@import './../abstracts/mixins';

.Inner-pages {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 54px;
  background: url(../../img/InnerPage-bg.png) center bottom no-repeat,
    url(../../img/InnerPage-bg.png) center top repeat-y;
  background-size: contain, contain, auto;
  @media screen and (max-width: $bp-medium-900) {
    flex-direction: column;
  }

  @media screen and (max-width: $bp-medium-900) {
    padding-top: 16px;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 6px 0px 54px 124px;
    html[dir='rtl'] & {
      font-family: 'Tajawal';
      padding: 6px 54px 0 124px;
    }
    @media screen and (max-width: $bp-medium-900) {
      padding: 6px 0px 15px 0px;
      html[dir='rtl'] & {
        padding: 6px 0px 15px 0px;
      }
    }

    &--list {
      border-top: 1px solid #00000040;
      width: 229px;
      font-family: 'Co-Headline';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.64px;
      text-transform: uppercase;
      padding: 15px 0;
      cursor: pointer;
      @media screen and (max-width: $bp-medium-900) {
        border-top: unset;
        padding: 10px 0;
        font-size: 20px;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 0px 200px 65px 112px;
    html[dir='rtl'] & {
      font-family: 'Tajawal';
      padding: 0px 50px 65px 200px;
    }
    @media screen and (max-width: $bp-medium-900) {
      padding: 0 20px 20px;
      html[dir='rtl'] & {
        padding: 0 20px 20px;
      }
    }

    h2 {
      background: linear-gradient(270.17deg, #ed6940 50%, #bc204b 98.6%);
      @include text-clip;
      font-family: 'Co-Headline';
      color: #bc204b;
      margin: 0 0 25px;
      font-size: 48px;
      @media screen and (max-width: $bp-medium-900) {
        font-size: 24px;
        margin: 0 0 20px;
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        line-height: 1.5;
        margin: 0 0 20px;
      }
    }

    .contentDescription {
      margin-bottom: 50px;
      @media screen and (max-width: $bp-medium-900) {
        font-size: 16px;
      }
      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }
    }
  }

  &__videoHero-InnerPages {
    height: 70vh;
    @media screen and (max-width: $bp-medium-900) {
      height: 50vh;
    }

    video {
      height: 100%;
    }

    .content {
      top: 65px;
      @media (min-width: $bp-medium-900) {
        html[dir='rtl'] & {
          top: 290px;
        }
      }
    }
    .icon__down {
      @media screen and (max-width: $bp-medium-900) {
        bottom: 16px;
      }
    }
  }
  .swiper-container {
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper {
    width: 816px;
    height: 577px;
    margin: 25px 0 25px 0;

    @media screen and (max-width: $bp-medium-900) {
      width: 86%;
      height: 243.752px;
      margin: 20px 0 10px 0;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .section {
    scroll-snap-align: start;
    /* Add any desired styling for sections */
  }

  .gradiant_title {
    background: linear-gradient(270.17deg, #ed6940 50%, #bc204b 98.6%);
    @include text-clip;
    font-family: 'Co-Headline';
    margin: 19px 0;
  }

  .collapse-toggle {
    display: flex;
    flex-direction: row;
    padding: 1px 0 15px 0;
    @media screen and (min-width: $bp-medium-900) {
      display: none;
      visibility: hidden;
    }
  }
  .toggle-text {
    color: #47ad42;
    font-family: 'Co-Headline';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1;
  }

  .toggle-icon {
    font-weight: bold;
    font-size: x-large;
  }

  .toggle-title {
    color: #89ccca;
    font-family: 'Co-Headline';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 0px;
    margin: 0;
    @media screen and (min-width: $bp-medium-900) {
      display: none;
      visibility: hidden;
    }
  }
  .toggle-border {
    @media screen and (max-width: $bp-medium-900) {
      border-bottom: 1px solid rgba(130, 145, 165, 0.4);
      margin: 0 20px;
      margin-bottom: 15px;
    }
  }

  /* YourComponent.css (or styled-components, etc.) */
  .swiper-pagination-bullet {
    width: 81px;
    height: 2px;
    background-color: #d9d9d9;
    opacity: 0.7;
    border-radius: 0%;
    @media screen and (max-width: $bp-medium-900) {
      width: 25px;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #47ad42; /* Customize the active dot color here */
    opacity: 1;
  }
}
