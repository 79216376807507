.static-map{
    &__pic{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    // @media (min-width: $bp-large-min) {
    //    height: 100vh;
    // }

}