@import './../abstracts/mixins';

.btn {
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  padding: 12px 18px;
  font-family: 'Co-Headline';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
  line-height: 1.284;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @media (min-width: $bp-medium-min) {
    font-size: 14px;
    line-height: 1.745;
  }

  &.btn-rounded {
    border-radius: 100px;
  }

  &.btn-primary {
    background: #fff;
    color: $c-maroon-flush;
    transition: background 0.3s ease, color 0.3s ease;

    span {
      background: linear-gradient(268.74deg, #ed6940 -5.79%, $c-maroon-flush 91.12%);
      @include text-clip;
      transition: color 0.3s ease;
    }

    &:hover {
      background: $c-maroon-flush;
      color: #fff !important;

      span {
        background: none;
        -webkit-text-fill-color: #fff;
        text-fill-color: #fff;
        color: #fff;
      }
    }
  }

  &.btn-notification-primary {
    background: #fff;
    color: $c-maroon-flush;
    transition: background 0.3s ease, color 0.3s ease;
    border: 1px solid transparent;
    padding: 15px 40px;
    @media screen and (max-width: $bp-medium-min) {
      padding: 15px 30px;
    }

    span {
      background: linear-gradient(268.74deg, #ed6940 -5.79%, $c-maroon-flush 91.12%);
      @include text-clip;
      transition: color 0.3s ease;
    }

    &:hover {
      background: $c-maroon-flush;
      color: #fff !important;
      border: 1px solid white;

      span {
        background: none;
        -webkit-text-fill-color: #fff;
        text-fill-color: #fff;
        color: #fff;
      }
    }
  }

  &.btn-secondary {
    background: linear-gradient(265.9deg, #ed6940 0%, $c-maroon-flush 100%);
    color: #fff;
    transition: background 0.3s ease, color 0.3s ease;

    span {
      color: #fff;
      transition: color 0.3s ease;
    }

    &:hover {
      background: #fff;
      color: $c-maroon-flush;

      span {
        background: linear-gradient(268.74deg, #ed6940 -5.79%, $c-maroon-flush 91.12%);
        @include text-clip;
      }
    }
  }

  &.btn-notification-secondary {
    background: linear-gradient(265.9deg, #ed6940 0%, $c-maroon-flush 100%);
    border: 1px solid white;
    color: #fff;
    transition: background 0.3s ease, color 0.3s ease;
    padding: 15px 40px;
    @media screen and (max-width: $bp-medium-min) {
      padding: 15px 30px;
    }

    span {
      color: #fff;
      transition: color 0.3s ease;
    }

    &:hover {
      background: #fff;
      color: $c-maroon-flush;

      span {
        background: linear-gradient(268.74deg, #ed6940 -5.79%, $c-maroon-flush 91.12%);
        @include text-clip;
      }
    }
  }

  &.btn-secondary-article {
    background: #ed6940;
    color: #fff;
    position: relative;
    transition: background 0.3s ease;

    &::before {
      background: linear-gradient(265.9deg, #ed6940 0%, $c-maroon-flush 100%);
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 1;
      transition: opacity 0.3s ease;
      z-index: $z-one;
    }

    span {
      color: #fff;
      position: relative;
      z-index: $z-low;
    }

    &:hover {
      background: $c-maroon-flush;
      color: #fff;

      &::before {
        opacity: 0;
      }
    }
  }

  &.btn-hero {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    color: #fff;
    transition: background 0.3s ease, color 0.3s ease;

    span {
      color: #fff;
      transition: color 0.3s ease;
    }

    &:hover {
      background: #fff;
      color: $c-maroon-flush;

      span {
        background: linear-gradient(268.74deg, #ed6940 -5.79%, $c-maroon-flush 91.12%);
        @include text-clip;
      }
    }
  }

  &.btn-block {
    display: block;
  }

  &.btn-collapse {
    background: none;
    line-height: 1;
    padding: 0;
  }

  &.btn-link {
    background: none;
    padding: 0;
  }

  html[dir='rtl'] & {
    font-family: 'Tajawal';
    font-weight: 700;
  }
}
