.spinner {
  margin: 50px 0;
  overflow: hidden;

  .spin {
    width: 56px;
    height: 56px;
    --c: radial-gradient(farthest-side, var(--spin-color) 92%, #0000);
    background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%, var(--c) 0 50%;
    background-size: 13.4px 13.4px;
    background-repeat: no-repeat;
    margin: 0 auto;
    text-indent: -9999px;
    animation: spinner 1s infinite;
  }

  @keyframes spinner {
    to {
      transform: rotate(0.5turn);
    }
  }
}
