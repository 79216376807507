.stacked-cta {
  @media (min-width: $bp-large-min) {
    height: 100vh;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__item {
    cursor: none;
    flex: 1;
    position: relative;
    height: 100%;
    //margin: 0 -1px;

    &:before {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      content: ' ';
      background: rgba(0, 0, 0, 0.7);
      z-index: $z-one * 2;
    }

    &.active {
      &:before {
        background: rgba(0, 0, 0, 0.3);
      }
    }

    video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-one;
    }

    &-content {
      color: #fff;
      position: absolute;
      z-index: $z-one * 2;
      bottom: 80px;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: opacity ease-in-out 0.5s;

      &.active {
        opacity: 1;
      }

      h3 {
        font-size: 14px;
        line-height: 1.221;
      }

      h2 {
        font-family: 'Co-headline';
        font-size: 48px;
        font-weight: 400;
        line-height: 2;

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }

      p {
        font-size: 24px;
        line-height: 1.333;
        + p {
          margin-top: 48px;
        }
      }
    }
  }

  .accordion__item {
    border: none;
    color: #fff;
    position: relative;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-one;
    }

    &:before {
      content: ' ';
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: $z-one * 2;
      display: block;
    }

    .accordion__heading {
      position: relative;
      z-index: $z-one * 3;

      .accordion__button {
        padding: 30px 20px;
        font-size: 26px;
        line-height: 1.284;

        &--collapse {
          display: block;
        }

        &--expand {
          display: none;
        }

        &[aria-expanded='true'] {
          .accordion__button--collapse {
            display: none;
          }

          .accordion__button--expand {
            display: block;
          }
        }
      }
    }

    .accordion__panel {
      padding: 0 20px 30px;
      z-index: $z-one * 3;
      position: relative;

      p {
        font-size: 18px;
        line-height: 1.222;
        + p {
          margin-top: 25px;
        }
      }
    }
  }

  &__cursor {
    border: 1px solid #fff;
    border-radius: 50%;
    backdrop-filter: blur(20px);
    color: #fff;
    display: none;
    height: 52px;
    line-height: 1.286;
    position: absolute;
    text-decoration: none;
    width: 52px;
    z-index: 1010;
  }

  &:hover {
    .stacked-cta__cursor {
      display: block;
    }
  }
}
