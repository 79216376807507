.notification-container {
  position: relative;
  top: 0;
  z-index: $z-one;
  padding: 10px 60px 15px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $bp-medium-min) {
    padding: 27px 0 26px 15px;
  }

  span {
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: white;
    text-align: left;
    margin-bottom: 15px;
  }

  &--actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &--layout {
    display: flex;
    flex-direction: row;
    background: #bc204b;
    z-index: 10000;
  }

  &--close {
    width: 200px;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    color: white;
    cursor: pointer;
    padding: 10px;
    @media screen and (max-width: $bp-medium-min) {
      width: 90px;
    }
  }
}
