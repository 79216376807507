body.sitemap {
  @media (min-width: $bp-medium-min) {
    .inner-page__content {
      background: url(../../img/modal-bg.png) right top no-repeat #fff;
      background-size: auto 100%;

      html[dir='rtl'] & {
        transform: scaleX(-1);

        * {
          transform: scaleX(-1);
        }
      }
    }
  }
}

.sitemap-content {
  margin: 0;

  @media (min-width: $bp-large-min) {
    margin: -35px 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      font-size: 18px;
      margin-bottom: 20px;
      font-family: 'Co-Headline';
      list-style: none;
      padding: 0 0 0 10px;

      @media (min-width: $bp-medium-min) {
        font-size: 22px;
      }

      html[dir='rtl'] & {
        font-family: 'Tajawal';
        padding: 0 10px 0 0;
      }

      &:before {
        content: '\2022';
        color: lightgray;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin: 0 0 0 -1em;

        html[dir='rtl'] & {
          margin: 0 -1em 0 0;
        }
      }

      a {
        color: $c-maroon-flush;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      ul {
        margin: 20px 0;
        padding: 0 0 0 10px;

        html[dir='rtl'] & {
          padding: 0 10px 0 0;
        }

        li {
          a {
            color: $c-atlantis;
          }
        }
      }
    }
  }
}
