@mixin grid-12-cols {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  gap: $column-gutter;
}

@mixin hr {
  background-color: rgba(65, 72, 85, 0.5);
  border: none;
  height: 1px;
  margin: 23px 0;
  padding: 0;
  width: 100%;
}

// This for text with gradiant bg
@mixin text-clip {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin rtl-direction {
  html[dir='rtl'] & {
    direction: rtl;
  }
}
