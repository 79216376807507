// colors
$c-outer-space: #414855;
$c-regent-gray: #8291a5;
$c-maroon-flush: #bc204b;
$c-burnt-sienna: #ed6940;
$c-golden-dream: #f3dc3e;
$c-atlantis: #78d64b;
$c-eden: #115740;
$c-lochmara: #0076ce;
$c-half-baked: #89ccca;
$c-burnt-sienna: #ed6940;
$c-apple: #47ad42;
$g-red: linear-gradient(180deg, #ed6940 0%, #bc204b 100%);
$g-yellow: linear-gradient(180deg, #f3dc3e 0%, #ed6940 100%);
$g-green: linear-gradient(180deg, #78d64b 0%, #115740 100%);
$g-blue: linear-gradient(180deg, #89ccca 0%, #0076ce 100%);

// defaults
$column-gutter: 20px;

// z-index
$z-negative: -1;
$z-zero: 0;
$z-one: 1;
$z-low: 10;
$z-medium: 50;
$z-high: 100;
$z-v-high: 200;
