@keyframes "color-change" {
	0% {
		color: white;
	}
	50% {
		color: rgb(209 255 102);
	}
	100% {
		color: white;
	}
}


.h1-greetings {
	text-align: center;
	text-transform: capitalize;
}
.container-greetings {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.choose-image-scroll{
 max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.sidebar-greetings {
  width: 75%;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 15px;
 
	h4 {
		text-transform: uppercase;
		color: #ffff;
		letter-spacing: 1px;
		margin: 10px 0;
	}
	img {
    width: 50%;
		object-fit: contain;
		letter-spacing: 1px;
		flex: 1;
	}
}
.main-greetings {
	width: 75%;
	display: flex;
	justify-items: center;
	flex-direction: column;
	align-items: center;
	cursor: auto;
}
textarea {
	width: 640px;
	height: 100px;
	margin-top: 30px;
	text-align: left;
}
.colorPicker {
	margin-top: 30px;
	label {
		font-weight: 600;
	}
}
.downloadIcon {
	top: 100px;
	right: 50px;
	height: 50px;
	width: 50px;
	object-fit: contain;
	cursor: pointer;
}
.imgs {
	display: flex;
}
.greeting-padding-container {
	padding: 30px 189px 300px;
}

@media only screen and (max-width: 1000px) {
  .canvas-width {
    width: 70vw;
    height: 100%;
  }

  textarea {
    width: 70vw;
  }

  .greeting-padding-container {
    padding: 20px 0 100px 0;
  }

  .downloadIcon {
    right: 0;
  }

  .sidebar-greetings {
    width: 65%;
  }
}

@media only screen and (max-width: 500px) {
	.link {
		padding: 0 !important;
	}
	.sidebar-greetings {
		h4 {
			font-size: 13px;
			margin: 5px 0;
		}
	}
}

// Version 1
.link {
  position: relative;
  padding: 12px 24px;
  color: #fff;
  transition: all 0.3s ease;
  text-transform: uppercase;
  text-decoration: none;
  top: 25px;

  @media only screen and (max-width: 1000px) and (min-width: 750px) {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: 750px) and (min-width: 400px) {
    margin-bottom: 50px;
  }
  // TOOLTIP BOTTOM TRIANGLE
  &:before {
    content: '';
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 50%;
    transform: translate3d(-50%, 0%, 0);
    transition: all 0.3s ease;
    transition-delay: 0.2s;
    width: 0;
    height: 0;
    top: 31px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #282828 transparent transparent transparent;
  }
  // TOOLTIP BUBBLE
  &:after {
    text-transform: none;
    content: attr(data-tooltip);
    font-size: 14px;
    position: absolute;
    color: #fff;
    background: #282828;
    padding: 8px 12px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 200px;
    opacity: 0;
    top: 19px;
    pointer-events: none;
    left: 50%;
    border-radius: 4px;
    transform: translate3d(-50%, 0%, 0);
    transition: all 0.3s ease;
    transition-delay: 0.2s;
  }
  &:hover {
    // TOOLTIP FADEIN AND TRANSLATION
    &:before,
    &:after {
      opacity: 1;
    }
    &:before {
      transform: translate3d(-50%, calc(-100% - 18px), 0);
    }
    &:after {
      transform: translate3d(-50%, calc(-100% - 16px), 0);
    }
  }
}

.sidebar-greetings {
	h4 {
		color: #bc204b;
	}
}

.text-language{
  html[dir='rtl'] & {
    font-family: 'Tajawal';
    text-align: right;
  }
}
