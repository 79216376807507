.content-hero {
  position: relative;
  text-align: center;
  color: white;

  &__desc {
    position: absolute;
    bottom: 8px;
    margin-bottom: 43px;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    font-family: 'Co-Headline';
    font-weight: 400;
    line-height: 1.5;
    font-size: 18px;

    @media (min-width: $bp-medium-min) {
      line-height: 1.5;
      font-size: 28px;
    }

    div {
      max-width: 335px;
      display: flex;
      flex-direction: column-reverse;
      line-height: 28px;

      @media (min-width: $bp-medium-min) {
        max-width: calc(100% - 2 * $column-gutter);
        line-height: 42px;
      }

      @media (min-width: $bp-navigation-min) {
        max-width: 995px;
        line-height: 42px;
      }
    }

    html[dir='rtl'] & {
      font-family: 'Tajawal';
      text-align: right;
    }

    @media (min-width: $bp-large-min) {
      margin-bottom: 157px;
    }

    &--icon {
      width: 83px;
      height: 24px;
      margin-left: 0;
      margin-bottom: 10px;

      @media (min-width: $bp-medium-min) {
        width: 141px !important;
        height: 40px !important;
      }

      @media (min-width: $bp-navigation-min) {
        margin-left: -97px;
        margin-bottom: 6px;

        html[dir='rtl'] & {
          margin-right: -97px;
        }
      }
    }
  }

  &__bg {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}
