.full-height {
  min-height: 100% !important;
  padding-top: 100px;
}

.headder__text {
  font-size: 24px;
  font-family: 'Co-Headline';
  color: white;
  margin: 0 auto 45px;
  text-align: center;

  @media (min-width: $bp-large-min) {
    font-size: 36px;
    max-width: 485px;
  }
}

.container-actions {
  display: flex;
  border: unset;
  border-bottom: 1px solid white;
  padding-left: 0;
  padding-right: 0;

  input {
    flex: 1;
    border: unset;
    background: unset;
    color: white;

    &:focus {
      outline: none;
      color: white;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1;
      font-size: 18px;
      font-family: 'Calibri';
    }
  }
}

.filed-list-box {
  border: unset;
  padding: 10px;

  @media (min-width: $bp-large-min) {
    padding: 0;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  padding-top: 40px;

  @media (min-width: $bp-large-min) {
    // padding-top: 105px;
    padding-top: 159px;
    width: auto;
  }
}

.container-actions {
  button {
    color: $c-burnt-sienna !important;
  }
}

.min-height-100 {
  min-height: 100%;
}
.contact-modal {
  background: white;
  max-width: 1200px;
  height: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -90px;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  z-index: $z-low - 1;
  box-shadow: 0px 20px 20px -15px rgba(0, 0, 0, 0.5);

  @media (min-width: $bp-large-min) {
    flex-direction: row;
    margin: auto;
    top: 0px;
  }

  & .contentBlock {
    margin-bottom: 20px;
  }

  &__header {
    background: linear-gradient(270.17deg, #ed6940 50%, #bc204b 98.6%);
    @include text-clip;
    margin-bottom: 10px;
    font-size: 24px;
    font-family: 'Co-Headline';
    font-weight: 400;

    @media (min-width: $bp-large-min) {
      font-size: 36px;
      margin-bottom: 19px;
    }

    html[dir='rtl'] & {
      font-family: 'Tajawal';
      font-size: 40px;
      font-weight: 500;
    }
  }

  &__container-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (min-width: $bp-large-min) {
      margin: 60px 97px;
    }
  }

  &__container-us {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: url(../../img/modal-bg.png) center no-repeat;
    background-size: auto 100%;

    @media (min-width: $bp-large-min) {
      &:before {
        border-left: 1px solid rgba(130, 145, 165, 0.4);
        content: ' ';
        position: absolute;
        top: 37px;
        bottom: 34px;
      }
    }
  }

  &__container-us-center {
    margin: 0 20px 20px 20px;
    border-top: 1px solid #d3d0d0;
    padding-top: 12px;

    @media (min-width: $bp-large-min) {
      padding: 168px 80px;
      margin: 0;
      border: unset;
    }

    h2 {
      font-size: 22px;
      font-family: 'Co-Headline';
      margin-bottom: 16px;

      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }
    }

    .contentDescription {
      p {
        font-size: 18px;
        color: #8291a5;
        font-family: 'Calibri';

        html[dir='rtl'] & {
          font-family: 'Tajawal';
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 0;

    label {
      font-size: 12px;
      font-family: 'Calibri';
      font-weight: 700;
      color: #414855;
      margin-bottom: 10px;

      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }
    }

    select,
    input {
      border: unset;
      border-bottom: 1px solid #414855;
      margin-bottom: 15px;
      font-size: 16px;
      color: #414855;
      font-family: 'Calibri';
      font-weight: 400;

      html[dir='rtl'] & {
        font-family: 'Tajawal';
      }

      @media (min-width: $bp-large-min) {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }

    textarea:focus,
    input:focus,
    select:focus {
      outline: none;
      border-bottom: 1px solid #47ad42;
    }
  }

  button {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 31px;

    @media (min-width: $bp-large-min) {
      width: 183px;
      height: 49px;
      margin-top: 35px;
      margin-bottom: 80px;
    }
  }

  .form-errors {
    display: none;
  }

  .invalid p {
    font-size: 14px;
    color: red;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}

.two__row__wrapper {
  .contact-us-bg {
    height: 100vh;
    width: 100%;
    object-fit: cover;

    @media (min-width: $bp-large-min) {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
  }

  /*.container-bleed__has-curved-bg {
    background-size: contain, cover, auto;
  }*/
}


  blockquote {
    color: #115740;
    background: linear-gradient(268.47deg, #78d64b 0%, #115740 99.49%);
    @include text-clip;
    font-family: 'Co-Headline';
    margin-bottom: 29px;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.25;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }

    @media (min-width: $bp-large-min) {
      line-height: 1.29;
      font-size: 40px;
    }

    &:before {
      content: '“';
    }

    &:after {
      content: '”';
    }

    html[dir='rtl'] & {
      &:before {
        content: '”';
      }

      &:after {
        content: '“';
      }
    }
  }
  .quote__author {
    font-weight: 700;
    font-size: 12px;
    font-family: 'Co-Headline';
    color: #115740;
    line-height: 1.2;

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }

    @media (min-width: $bp-large-min) {
      font-weight: 700;
      font-size: 18px;
      line-height: 2;
    }
  }

.thank-you-message {
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  font-family: 'Co-Headline';
  position: relative;
  margin-bottom: 20px;

  @media (min-width: $bp-large-min) {
    font-size: 37px;
  }

  html[dir='rtl'] & {
    font-family: 'Tajawal';
  }

  &::before {
    background: url(../../img/heart-hero.png) center top no-repeat;
    content: ' ';
    background-size: 120px;
    display: block;
    height: 140px;
  }

  &::after {
    content: 'King Salamn Park';
    display: block;
    position: absolute;
    top: 50px;
    left: 90px;
    @media (min-width: $bp-large-min) {
      top: 40px;
    }

    html[dir='rtl'] & {
      content: 'حديقة الملك سلمان';
    }
  }
}

.form-errors {
  .invalid {
    div {
      display: none;
    }
  }
}

.container-actions {
  .invalid {
    color: #f3dc3e;
    position: absolute;

    p {
      margin-top: -14px;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
