.testimonials {
  background: url(../../img/Combined-Shape-Bs.png) center no-repeat #8291a5;
  padding: 0 0 20px 0;

  @media (min-width: $bp-large-min) {
    background-size: 100% auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 60px 0;

    @media (min-width: $bp-large-min) {
      flex-direction: row;
      gap: 126px;
      height: 100vh;
      padding: 0;

    }
  }

  &__img {
    margin-bottom: 15px;

    @media (min-width: $bp-large-min) {
      position: relative;
      margin-bottom: 30px;
    }
  }

  &__qoute{ 
    @media (min-width: $bp-large-min) {
      height: 200px;  
    }
  }

  &__king,
  &__prince {
    flex-direction: column;
    display: flex;
    flex: 1;
    align-items: center;

    div {
      font-family: 'Co-Headline';
      font-weight: 300;
      font-size: 16px;
      line-height: 1.3;
      color: #ffffff;
      max-width: 489px;
      text-align: center;
      margin-bottom: 8px;

      html[dir='rtl'] & {
        font-family: "Tajawal";
      }

      @media (min-width: $bp-large-min) {
        font-size: 28px;
        margin-bottom: 35px;
        line-height: 1.6;
      }
    }

    span {
      color: #ffffff;
      font-family: 'Co-Headline';
      font-weight: 700;
      max-width: 250px;
      text-align: center;
      font-size: 12px;
      line-height: 1.16;

      html[dir='rtl'] & {
        font-family: "Tajawal";
      }

      @media (min-width: $bp-large-min) {
        font-size: 18px;
        max-width: 374px;
        line-height: 1.5;
      }
    }
  }
}
