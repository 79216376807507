@import '../abstracts/variables';
@import '../abstracts/breakpoints';
@import 'swiper/swiper-bundle.min';

.latest_NewsHome {
  position: relative;

  /* Customize Swiper's navigation buttons */
  .latest-news-button-prev,
  .latest-news-button-next {
    width: 60px; // Adjust size as needed
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  /* Position the previous button to the left */
  .latest-news-button-prev {
    margin-left: -12%;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8yOTM3XzMzMDYpIj4KPGNpcmNsZSByPSIyOS41IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAzMCAzMCkiIHN0cm9rZT0id2hpdGUiLz4KPC9nPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMjkzN18zMzA2KSI+CjxtYXNrIGlkPSJtYXNrMF8yOTM3XzMzMDYiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjE3IiB5PSIyMCIgd2lkdGg9IjI1IiBoZWlnaHQ9IjIwIj4KPHBhdGggZD0iTTI3IDIwTDI4LjQxIDIxLjQxTDIwLjgzIDI5SDQyVjMxSDIwLjgzTDI4LjQxIDM4LjU5TDI3IDQwTDE3IDMwTDI3IDIwWiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzI5MzdfMzMwNikiPgo8cGF0aCBkPSJNMjcgMjBMMjguNDEgMjEuNDFMMjAuODMgMjlINDJWMzFIMjAuODNMMjguNDEgMzguNTlMMjcgNDBMMTcgMzBMMjcgMjBaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfYl8yOTM3XzMzMDYiIHg9Ii0yMCIgeT0iLTIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVHYXVzc2lhbkJsdXIgaW49IkJhY2tncm91bmRJbWFnZUZpeCIgc3RkRGV2aWF0aW9uPSIxMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9ImluIiByZXN1bHQ9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMjkzN18zMzA2Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8yOTM3XzMzMDYiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjkzN18zMzA2Ij4KPHJlY3Qgd2lkdGg9IjI1IiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNDIgMjApIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')
      center center no-repeat;
    top: calc(50% + 40px);

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8xOTM0XzE0NjI1KSI+CjxjaXJjbGUgY3g9IjMwIiBjeT0iMzAiIHI9IjMwIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDMwIDMwKSIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMTkzNF8xNDYyNSkiPgo8bWFzayBpZD0ibWFzazBfMTkzNF8xNDYyNSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTciIHk9IjIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjAiPgo8cGF0aCBkPSJNMjcgNDBMMjguNDEgMzguNTlMMjAuODMgMzFINDJWMjlIMjAuODNMMjguNDEgMjEuNDFMMjcgMjBMMTcgMzBMMjcgNDBaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTkzNF8xNDYyNSkiPgo8cGF0aCBkPSJNMjcgNDBMMjguNDEgMzguNTlMMjAuODMgMzFINDJWMjlIMjAuODNMMjguNDEgMjEuNDFMMjcgMjBMMTcgMzBMMjcgNDBaIiBmaWxsPSIjNDdBRDQyIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzE5MzRfMTQ2MjUiIHg9Ii0yMCIgeT0iLTIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVHYXVzc2lhbkJsdXIgaW49IkJhY2tncm91bmRJbWFnZUZpeCIgc3RkRGV2aWF0aW9uPSIxMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9ImluIiByZXN1bHQ9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMTkzNF8xNDYyNSIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMTkzNF8xNDYyNSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xOTM0XzE0NjI1Ij4KPHJlY3Qgd2lkdGg9IjI1IiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDIgNDApIHJvdGF0ZSgtMTgwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
    }
  }
  /* Position the next button to the right */
  .latest-news-button-next {
    margin-right: -12%;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8yOTM3XzMyOTcpIj4KPGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMjkuNSIgc3Ryb2tlPSJ3aGl0ZSIvPgo8L2c+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yOTM3XzMyOTcpIj4KPG1hc2sgaWQ9Im1hc2swXzI5MzdfMzI5NyIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTgiIHk9IjIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjAiPgo8cGF0aCBkPSJNMzMgMjBMMzEuNTkgMjEuNDFMMzkuMTcgMjlIMThWMzFIMzkuMTdMMzEuNTkgMzguNTlMMzMgNDBMNDMgMzBMMzMgMjBaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMjkzN18zMjk3KSI+CjxwYXRoIGQ9Ik0zMyAyMEwzMS41OSAyMS40MUwzOS4xNyAyOUgxOFYzMUgzOS4xN0wzMS41OSAzOC41OUwzMyA0MEw0MyAzMEwzMyAyMFoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzI5MzdfMzI5NyIgeD0iLTIwIiB5PSItMjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUdhdXNzaWFuQmx1ciBpbj0iQmFja2dyb3VuZEltYWdlRml4IiBzdGREZXZpYXRpb249IjEwIi8+CjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8yOTM3XzMyOTciLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2JhY2tncm91bmRCbHVyXzI5MzdfMzI5NyIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yOTM3XzMyOTciPgo8cmVjdCB3aWR0aD0iMjUiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOCAyMCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K')
      center center no-repeat;
    top: calc(50% + 40px);

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8xOTM0XzE0NjE2KSI+CjxjaXJjbGUgY3g9IjMwIiBjeT0iMzAiIHI9IjMwIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8xOTM0XzE0NjE2KSI+CjxtYXNrIGlkPSJtYXNrMF8xOTM0XzE0NjE2IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIxOCIgeT0iMjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyMCI+CjxwYXRoIGQ9Ik0zMyAyMEwzMS41OSAyMS40MUwzOS4xNyAyOUgxOFYzMUgzOS4xN0wzMS41OSAzOC41OUwzMyA0MEw0MyAzMEwzMyAyMFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF8xOTM0XzE0NjE2KSI+CjxwYXRoIGQ9Ik0zMyAyMEwzMS41OSAyMS40MUwzOS4xNyAyOUgxOFYzMUgzOS4xN0wzMS41OSAzOC41OUwzMyA0MEw0MyAzMEwzMyAyMFoiIGZpbGw9IiM0N0FENDIiLz4KPC9nPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2JfMTkzNF8xNDYxNiIgeD0iLTIwIiB5PSItMjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUdhdXNzaWFuQmx1ciBpbj0iQmFja2dyb3VuZEltYWdlRml4IiBzdGREZXZpYXRpb249IjEwIi8+CjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8xOTM0XzE0NjE2Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8xOTM0XzE0NjE2IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE5MzRfMTQ2MTYiPgo8cmVjdCB3aWR0aD0iMjUiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOCAyMCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
    }
  }
}

.swiper {
  padding-bottom: 48px;

  @media (min-width: $bp-medium-min) {
    padding-bottom: 88px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 60px;
    width: 60px;
    &:after {
      display: none;
    }

    @media (max-width: $bp-large-max) {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0.45;
    }
  }

  .swiper-button-next {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8yOTM3XzMyOTcpIj4KPGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMjkuNSIgc3Ryb2tlPSJ3aGl0ZSIvPgo8L2c+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yOTM3XzMyOTcpIj4KPG1hc2sgaWQ9Im1hc2swXzI5MzdfMzI5NyIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTgiIHk9IjIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjAiPgo8cGF0aCBkPSJNMzMgMjBMMzEuNTkgMjEuNDFMMzkuMTcgMjlIMThWMzFIMzkuMTdMMzEuNTkgMzguNTlMMzMgNDBMNDMgMzBMMzMgMjBaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMjkzN18zMjk3KSI+CjxwYXRoIGQ9Ik0zMyAyMEwzMS41OSAyMS40MUwzOS4xNyAyOUgxOFYzMUgzOS4xN0wzMS41OSAzOC41OUwzMyA0MEw0MyAzMEwzMyAyMFoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzI5MzdfMzI5NyIgeD0iLTIwIiB5PSItMjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUdhdXNzaWFuQmx1ciBpbj0iQmFja2dyb3VuZEltYWdlRml4IiBzdGREZXZpYXRpb249IjEwIi8+CjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8yOTM3XzMyOTciLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2JhY2tncm91bmRCbHVyXzI5MzdfMzI5NyIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yOTM3XzMyOTciPgo8cmVjdCB3aWR0aD0iMjUiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOCAyMCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K')
      center center no-repeat;
    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8xOTM0XzE0NjE2KSI+CjxjaXJjbGUgY3g9IjMwIiBjeT0iMzAiIHI9IjMwIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8xOTM0XzE0NjE2KSI+CjxtYXNrIGlkPSJtYXNrMF8xOTM0XzE0NjE2IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIxOCIgeT0iMjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyMCI+CjxwYXRoIGQ9Ik0zMyAyMEwzMS41OSAyMS40MUwzOS4xNyAyOUgxOFYzMUgzOS4xN0wzMS41OSAzOC41OUwzMyA0MEw0MyAzMEwzMyAyMFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF8xOTM0XzE0NjE2KSI+CjxwYXRoIGQ9Ik0zMyAyMEwzMS41OSAyMS40MUwzOS4xNyAyOUgxOFYzMUgzOS4xN0wzMS41OSAzOC41OUwzMyA0MEw0MyAzMEwzMyAyMFoiIGZpbGw9IiM0N0FENDIiLz4KPC9nPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2JfMTkzNF8xNDYxNiIgeD0iLTIwIiB5PSItMjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUdhdXNzaWFuQmx1ciBpbj0iQmFja2dyb3VuZEltYWdlRml4IiBzdGREZXZpYXRpb249IjEwIi8+CjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8xOTM0XzE0NjE2Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8xOTM0XzE0NjE2IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE5MzRfMTQ2MTYiPgo8cmVjdCB3aWR0aD0iMjUiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOCAyMCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
    }
    right: 50px;
    top: calc(50% + 40px);
  }

  .swiper-button-prev {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8yOTM3XzMzMDYpIj4KPGNpcmNsZSByPSIyOS41IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAzMCAzMCkiIHN0cm9rZT0id2hpdGUiLz4KPC9nPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMjkzN18zMzA2KSI+CjxtYXNrIGlkPSJtYXNrMF8yOTM3XzMzMDYiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjE3IiB5PSIyMCIgd2lkdGg9IjI1IiBoZWlnaHQ9IjIwIj4KPHBhdGggZD0iTTI3IDIwTDI4LjQxIDIxLjQxTDIwLjgzIDI5SDQyVjMxSDIwLjgzTDI4LjQxIDM4LjU5TDI3IDQwTDE3IDMwTDI3IDIwWiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzI5MzdfMzMwNikiPgo8cGF0aCBkPSJNMjcgMjBMMjguNDEgMjEuNDFMMjAuODMgMjlINDJWMzFIMjAuODNMMjguNDEgMzguNTlMMjcgNDBMMTcgMzBMMjcgMjBaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfYl8yOTM3XzMzMDYiIHg9Ii0yMCIgeT0iLTIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVHYXVzc2lhbkJsdXIgaW49IkJhY2tncm91bmRJbWFnZUZpeCIgc3RkRGV2aWF0aW9uPSIxMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9ImluIiByZXN1bHQ9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMjkzN18zMzA2Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8yOTM3XzMzMDYiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjkzN18zMzA2Ij4KPHJlY3Qgd2lkdGg9IjI1IiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNDIgMjApIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')
      center center no-repeat;
    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfYl8xOTM0XzE0NjI1KSI+CjxjaXJjbGUgY3g9IjMwIiBjeT0iMzAiIHI9IjMwIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDMwIDMwKSIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMTkzNF8xNDYyNSkiPgo8bWFzayBpZD0ibWFzazBfMTkzNF8xNDYyNSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTciIHk9IjIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjAiPgo8cGF0aCBkPSJNMjcgNDBMMjguNDEgMzguNTlMMjAuODMgMzFINDJWMjlIMjAuODNMMjguNDEgMjEuNDFMMjcgMjBMMTcgMzBMMjcgNDBaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTkzNF8xNDYyNSkiPgo8cGF0aCBkPSJNMjcgNDBMMjguNDEgMzguNTlMMjAuODMgMzFINDJWMjlIMjAuODNMMjguNDEgMjEuNDFMMjcgMjBMMTcgMzBMMjcgNDBaIiBmaWxsPSIjNDdBRDQyIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzE5MzRfMTQ2MjUiIHg9Ii0yMCIgeT0iLTIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVHYXVzc2lhbkJsdXIgaW49IkJhY2tncm91bmRJbWFnZUZpeCIgc3RkRGV2aWF0aW9uPSIxMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9ImluIiByZXN1bHQ9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMTkzNF8xNDYyNSIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMTkzNF8xNDYyNSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xOTM0XzE0NjI1Ij4KPHJlY3Qgd2lkdGg9IjI1IiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDIgNDApIHJvdGF0ZSgtMTgwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
    }

    left: auto;
    right: 50px;
    top: calc(50% - 40px);
  }
}

.swiper-pagination-bullet {
  position: relative;
  width: 42px;
  height: auto;
  text-align: left;
  border-radius: 0;
  opacity: 1;
  background-color: transparent;

  @media (min-width: $bp-large-min) {
    width: 82px;
  }

  i {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: $z-one;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }
  b {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 0%;
    height: 2px;
    background-color: $c-golden-dream;
    //progress bar
  }

  html[dir='rtl'] & {
    text-align: right;

    i,
    b {
      left: auto;
      right: 0;
    }
  }

  .slideshow-carousel &,
  .vertical-carousel & {
    b {
      background-color: $c-apple;
    }
  }
}
.swiper-pagination-bullet-active {
  background-color: transparent;
  @media (min-width: $bp-large-min) {
    b {
      animation-name: countingBar;
      animation-duration: 5s; //autoplay
      animation-timing-function: ease-in;
      animation-iteration-count: 1;
      animation-direction: alternate;
      animation-fill-mode: forwards;
    }

    .slideshow-carousel &,
    .vertical-carousel & {
      b {
        animation-duration: var(--animation-duration, 10s);
      }
    }
  }

  @media (max-width: $bp-large-max) {
    b {
      width: 100%;
    }
  }
}

@keyframes countingBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
