@import 'react-modal-video/scss/modal-video.scss';

.video-middle-container {
  position: relative;
  text-align: center;
  color: white;
  height: 100vh;

  @media (max-width: $bp-medium-min) {
    height: 300px;
  }

  &__centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;

    @media (max-width: $bp-medium-min) {
      padding: 50px 30px;
    }
  }

  video {
    width: 100%;
    height: 100vh;

    @media (max-width: $bp-medium-min) {
      height: 300px;
    }
  }

  .title {
    font-weight: 400;
    font-size: 26px;
    font-family: 'Co-Headline';
    margin-bottom: 10px;
    color: #ffffff;
    max-width: 428px;
    margin: 0 auto 10px;

    @media (min-width: $bp-large-min) {
      margin-bottom: 18px;
      font-size: 36px;
    }

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }
  }

  .desc {
    font-weight: 400;
    font-size: 18px;
    font-family: 'Calibri';
    color: #ffffff;
    max-width: 590px;
    margin: 0 auto 20px;

    @media (min-width: $bp-large-min) {
      margin-bottom: 21px;
      font-size: 45px;
    }

    html[dir='rtl'] & {
      font-family: 'Tajawal';
    }
  }

  .icon-overlay {
    &::before {
      background: url(../../img/full-layover-video.png) center center no-repeat;
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      z-index: $z-one;
    }

    @media (max-width: $bp-medium-max) {
      background: url(../../img/mobile-layover-video.png) center bottom no-repeat;
    }
  }

  .video-overlay {
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  i {
    cursor: pointer;

    svg {
      width: 50px;
      height: 50px;

      @media (min-width: $bp-large-min) {
        width: 100px;
        height: 100px;
      }
    }

    &:hover {
      svg {
        fill: white;
        stroke: #47ad42;
        path {
          fill: #47ad42;
        }
      }
    }
  }
}

.modal-video-body {
  @media (max-width: $bp-medium-max) {
    padding: 0 20px !important;
  }
}
